import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useParams, useHistory, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image,
  Card 
} from 'semantic-ui-react';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import './index.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';
import UsuarioMedusaService from '../../services/usuarioMedusaService';
import IArea from '../../models/area';
import IUsuarioMedusa from '../../models/usuarioMedusa';
import Loading from '../../components/Loading';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  id:string, 
  redirectTo:any, 
  loading:boolean, 
  modalFiltroOpen:boolean, 
  noResults:boolean, 
  searchString: string, 
  currentPage:number, 
  modelosEditor:any[], 
  modelosEditorFiltradas:any[], 
  nextPage:string | null, 
  filterDate:string, 
  currentArea: IArea | null,
  usuarioMedusa: IUsuarioMedusa | null,
  whatsappUrl: string,
  token: string | null,
};

export default class MidiasAreas extends React.Component<MyProps,MyState>{
  constructor(props:any) {
    super(props);
    console.log('props', this.props);
    console.log('props id', this.props.match.params.id);
    this.state = {
      id: this.props.match.params.id, 
      redirectTo: false, 
      loading:true, 
      modalFiltroOpen:false, 
      noResults:false, 
      searchString: '', 
      currentPage: 1, 
      modelosEditor: [], 
      modelosEditorFiltradas: [], 
      nextPage: '',
      filterDate: '',
      currentArea: null,
      usuarioMedusa: null,
      whatsappUrl: 'https://api.whatsapp.com/send?phone=5538991999244&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20adicionais%20',
      token: ''
    };
  }

  componentDidMount = async () => {
    console.log('componentDidMount MidiasAreas');
    const localToken = localStorage.getItem('token');
    this.setState({token:localToken});
    await this.getMedusaInfo(localToken);
    await this.getAreaInfo(parseInt(this.state.id, 10));
    await this.handleGetMore(parseInt(this.state.id, 10),this.state.currentPage);
    this.setState({loading:false});
  }

  getAreaInfo = async (id_area:number) => {
    const areaInfoResponse = await EditorAreaService.getAreaInfo(id_area);
    this.setState({currentArea:areaInfoResponse});
  };

  getMedusaInfo = async (token:any) => {
    const medusaInfo = await UsuarioMedusaService.get(token);
    this.setState({usuarioMedusa:medusaInfo});
  };

  handleGetMore = async (id_area:number, page: number, date:any = '', clearValues: any = false) => {
    const modelosResponse = await EditorAreaService.getModelosArea(id_area, page, date);
    const currentAux = this.state.modelosEditor;
    const {currentPage} = this.state;

    const newArr = [...currentAux, ...modelosResponse.results];
    if(clearValues)
    {
      const hasResults = modelosResponse.results.length===0;
      this.setState({
        currentPage: currentPage+1, 
        nextPage: modelosResponse.next, 
        modelosEditor: modelosResponse.results, 
        modelosEditorFiltradas: modelosResponse.results,
        noResults:hasResults
      });
    }
    else
    {
      const hasResults = newArr.length===0;
      this.setState({
        currentPage: currentPage+1, 
        nextPage: modelosResponse.next, 
        modelosEditor: newArr, 
        modelosEditorFiltradas: newArr,
        noResults:hasResults
      });
    }
  };

  handleFilter = async () => {
    const initialPage = 1;
    this.setState({
        currentPage: initialPage, 
        nextPage: '', 
        modelosEditor: [], 
        modelosEditorFiltradas: [],
        noResults:false
      });
    if(this.state.filterDate.length>2)
    {
      const periodoSplit = this.state.filterDate.split('-');
      let novoPeriodo = '';
      novoPeriodo = `${periodoSplit[2]}/${periodoSplit[1]}/${periodoSplit[0]}`;
      
      this.handleGetMore(parseInt(this.state.id, 10), initialPage,novoPeriodo, true);
    }
  };

  handleSearch = async (e:any)=>{
    let results = [];
    if(this.state.searchString.trim().toUpperCase()==='')
    {
      const {modelosEditor} = this.state;
      results = modelosEditor;
      this.setState({modelosEditorFiltradas:results});
    }
    else
    {
      for(let i=0; i<this.state.modelosEditor.length;i+= 1)
      {
        if(this.state.modelosEditor[i].titulo.toUpperCase().includes(this.state.searchString.trim().toUpperCase()))
        {
          results.push(this.state.modelosEditor[i]);
        }
      }
      this.setState({modelosEditorFiltradas:results});
    }
  };

  openInNewTab = (url:any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if(newWindow) newWindow.opener = null;
  }

  handleOpenModelo = async (id_modelo:number, currentArea:any, usuarioMedusa: any) =>{    
    console.log('handleOpenModelo', String(id_modelo));
    if(currentArea!==null)
    {
      if(currentArea.tipo_venda!=='GRATIS')
      {
        switch(currentArea.tipo_venda)
        {
          case 'CONTEUDO':
            if(usuarioMedusa.tipos_areas_atuacao.includes('CONTEUDO'))
            {
              this.setState({redirectTo:Rotas.MODELO.replace(':id',String(id_modelo))});
            }
            else
            {
              alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
              this.openInNewTab(this.state.whatsappUrl);
            }
            break;
          case 'NOTICIA':        
            if(usuarioMedusa.tipos_areas_atuacao.includes('NOTICIA'))
            {
              this.setState({redirectTo:Rotas.MODELO.replace(':id',String(id_modelo))});
            }
            else
            {
              alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
              this.openInNewTab(this.state.whatsappUrl);
            }
            break;
          case 'COMEMORATIVA':        
            if(usuarioMedusa.tipos_areas_atuacao.includes('COMEMORATIVA'))
            {
              this.setState({redirectTo:Rotas.MODELO.replace(':id',String(id_modelo))});
            }
            else
            {
              alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
              this.openInNewTab(this.state.whatsappUrl);
            }
            break;
          case 'INSPIRACAO':        
            if(usuarioMedusa.tipos_areas_atuacao.includes('INSPIRACAO'))
            {
              this.setState({redirectTo:Rotas.MODELO.replace(':id',String(id_modelo))});
            }
            else
            {
              alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
              this.openInNewTab(this.state.whatsappUrl);
            }
            break;
          default:
            alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
            this.openInNewTab(this.state.whatsappUrl);
            break;
        }
      }
      else
      {
        this.setState({redirectTo:Rotas.MODELO.replace(':id',String(id_modelo))});
      }
    }
  };

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    const modalButton = (
      <Button inverted>
        <Icon name='list alternate' />
        Filtros
      </Button>
    );
    return (
      <Container fluid className='midias-areas page'>
        <Menu stackable fluid borderless className='menu-advogar' attached='top'>
          <Menu.Menu className='menu-button'>
            <Menu.Item>
              <MenuAdvogar />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item className='menu-breadcrumb'>
            <Breadcrumb>
              {breadCrumb[Rotas.CRIAR_MIDIAS].map(bread =>
                bread.link ? (
                  <>
                    <Breadcrumb.Section
                      as={NavLink}
                      to={bread.key}
                      active={bread.active}
                    >
                      {bread.content}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                  </>
                ) : (
                  <Breadcrumb.Section active={bread.active}>
                    {bread.content}
                  </Breadcrumb.Section>
                )
              )}
            </Breadcrumb>
          </Menu.Item>
          <Menu.Menu className='busca'>
            <Menu.Item>
              <Input action={{
                  icon: 'search',
                  onClick: this.handleSearch
                }} 
                value={this.state.searchString}
                id="searchMenu"
                onChange={(event)=>{this.setState({searchString:event.target.value})}}
                placeholder='Pesquisar...'/>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right' className='filtros'>
            <Menu.Item>
              <Modal
                onClose={() => this.setState({modalFiltroOpen:false})}
                onOpen={() => this.setState({modalFiltroOpen:true})}
                open={this.state.modalFiltroOpen}
                trigger={modalButton}
                size='small'
                closeIcon
                closeOnEscape
                closeOnDimmerClick
              >
                <Modal.Header>Filtro</Modal.Header>
                <Modal.Content>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h2' textAlign='center'>
                          Selecione a Tag:
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Form>
                          <input type='date' value={this.state.filterDate} className='filterInput' onChange={(event) => {
                                this.setState({filterDate:event.target.value});  
                              }}/>
                          <Button as='button' className='btnFilterModal' fluid primary onClick={() => {this.handleFilter();this.setState({modalFiltroOpen:false});}}>
                            Filtrar resultados
                          </Button>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
              </Modal>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container fluid className='container-body'>
          <div className="list-cards-container">
            <div className="list-cards">
              {this.state.modelosEditorFiltradas.map((modelo, i) => {
                return (
                  <Card className='cardMidia' onClick={()=>{this.handleOpenModelo(parseInt(modelo.id, 10), this.state.currentArea, this.state.usuarioMedusa)}} key={modelo.id}>
                    <Image src={`${constants.BASE_URL}${modelo.preview}`} wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>{modelo.titulo}</Card.Header>
                    </Card.Content>
                    <Card.Content extra>
                      {modelo.descricao || 'Sem descricao'}
                    </Card.Content>
                  </Card>
                )})
              }
            </div>
          </div>
          {
            this.state.nextPage!=null ?
              <div className="list-cards-footer">
                <Button as='button' fluid primary onClick={() => {this.handleGetMore(parseInt(this.state.id, 10),this.state.currentPage)}}>
                  Carregar mais itens
                </Button>
              </div>
              :
              null
          }
          {
            this.state.noResults ?
            <div className='no-results-container'>
              <Header as='h2' icon>
                <Icon name='clone' />
                Nenhum resultado encontrado!
                <Header.Subheader>
                  Utilize o filtro para alterar os resultados!
                </Header.Subheader>
              </Header>
            </div>
            : 
            null
          }

        </Container>
      </Container>
    );
  }
};

