import React, { useContext, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container, Grid, Header, Button } from 'semantic-ui-react';

import DashboardHeader from './components/header';
import Rotas from '../../utils/rotas';
import Loading from '../../components/Loading';

import './dashboard.scss';
import UsuarioStore from '../../stores/usuarioStore';
import UsuarioMedusaStore from '../../stores/usuarioMedusaStore';

import IUsuarioMedusa from '../../models/usuarioMedusa';
import IUsuario from '../../models/usuario';

import UsuarioService from '../../services/usuarioService';
import UsuarioMedusaService from '../../services/usuarioMedusaService';

type MyProps = { };
type MyState = { 
  redirectTo:any, 
  loading:boolean, 
  usuarioMedusa: IUsuarioMedusa | null, 
  usuarioInfo:IUsuario | null, 
  token: string | null,
};

export default class Dashboard extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {redirectTo: false, loading:true, usuarioMedusa: null, usuarioInfo: null, token: ''};  
  }

  componentDidMount = async () => {
    try {
      const localToken = localStorage.getItem('token');
      const medusaInfo = await UsuarioMedusaService.get(localToken);
      if(medusaInfo.logo_original==null && medusaInfo.logo_colorida==null && medusaInfo.logo_branca==null)
      {
        this.setState({loading:false, redirectTo:Rotas.UPLOAD_LOGO});
        return;
      }
      const ativaPresentes = await UsuarioService.ativarPresentes(localToken);
      const usuarioAdvInfo = await UsuarioService.usuarioInfo(localToken);
      this.setState({loading:false, usuarioMedusa: medusaInfo, usuarioInfo: usuarioAdvInfo, token: localToken});
    }
    catch (error) {
      await this.logout();
      this.setState({redirectTo:Rotas.LOGIN});
    }
  }

  logout = async () =>{
    localStorage.setItem('isLogged', JSON.stringify(false));
    localStorage.setItem('loginError', '');
    localStorage.setItem('token', '');
    localStorage.removeItem('token');
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return(
      <Container className='dashboard' fluid>
      <DashboardHeader usuario={this.state.usuarioInfo} usuarioMedusa={this.state.usuarioMedusa} />
      <Container className='actions actionsContainer'>
        <Grid columns={3} stackable className='actions__buttons'>
          <Grid.Column>
            <Button
              as='button'
              fluid
              primary
              onClick={() => {
                this.setState({redirectTo:Rotas.CRIAR_MIDIAS});
              }}
            >
              Criar Mídias
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button as='button' fluid primary 
              onClick={() => {
                this.setState({redirectTo:Rotas.MEUS_MODELOS});
              }}
            >
              Meus Modelos
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button as='button' fluid primary 
              onClick={() => {
                this.setState({redirectTo:Rotas.ENVIAR_SUGESTAO});
              }}
              >
              Enviar Sugestão
            </Button>
          </Grid.Column>
        </Grid>
      </Container>
      <a className='whatsapp-widget' 
          rel='noopener noreferrer'
          target='_blank'
          href='https://api.whatsapp.com/send?phone=5538991999244&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20adicionais%20' 
          title='Show Chat'>
            <svg width="20" viewBox="0 0 24 24"><defs/><path fill="#eceff1" d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"/><path fill="#4caf50" d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"/><path fill="#fafafa" d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"/></svg> Entrar em contato
      </a>
    </Container>
    );
  }
}

