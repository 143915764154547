import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Modal,
  Select,
  Checkbox,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import PlanoGratisConvenioImg from '../../../assets/imgs/cadastro/plano_convenio_free.png';
import PlanoBasicConvenioImg from '../../../assets/imgs/cadastro/plano_convenio_basic.png';
import PlanoPremiumConvenioImg from '../../../assets/imgs/cadastro/plano_convenio_premium.png';
import PlanoProConvenioImg from '../../../assets/imgs/cadastro/plano_convenio_pro.png';
import PlanoBasicImg from '../../../assets/imgs/cadastro/plano_basic.png';
import PlanoPremiumImg from '../../../assets/imgs/cadastro/plano_premium.png';
import PlanoProImg from '../../../assets/imgs/cadastro/plano_pro.png';
import PlanoBasicAnualImg from '../../../assets/imgs/cadastro/plano_basic_anual.png';
import PlanoPremiumAnualImg from '../../../assets/imgs/cadastro/plano_premium_anual.png';
import PlanoPremiumAnualImgAtualizado from '../../../assets/imgs/cadastro/plano_premium_anual_atualizado.png';
import PlanoProAnualImg from '../../../assets/imgs/cadastro/plano_pro_anual.png';
import PlanoProAnualImgAtualizado from '../../../assets/imgs/cadastro/plano_pro_anual_atualizado.png';

import AuthService from '../../../services/authService';
import RdStation from '../../../services/rdStation';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';
import CountdownTimer from '../../../components/CountdownTimer';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  id_plano:string, 
  plano:string, 
  show_offer:boolean, 
  current_promo:string, 
  show_modal:boolean, 
  show_modal_site:boolean, 
  site_profissional_mensal:boolean, 
  periodo_pagamento:string, 
  periodo_pagamento_arr:any, 
  super_combo: boolean,
  super_combo_convenio: boolean,
  site_profissional_presente: boolean,
  cartao_interativo_presente: boolean,
  filtro_instagram_presente: boolean,
  ads_presente: boolean,
  ads_convenio_presente: boolean,
  show_modal_super_combo: boolean,
  show_modal_super_combo_convenio: boolean,
  show_modal_presente_site_profissional: boolean,
  show_modal_presente_cartao_interativo: boolean,
  show_modal_presente_filtro_instagram: boolean,
  show_modal_presente_ads: boolean,
  show_modal_presente_ads_convenio: boolean,
  show_offer_ads: boolean,
  has_show_super_combo_offer: boolean,
  cep_permite_convenio: boolean,
  convenio_cep_matriz:any, 
  cidade_convenio:string, 
};

export default class RegistrarPlano extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      id_plano:'', 
      plano:'', 
      show_offer:false, 
      current_promo:'', 
      show_modal: false,
      show_modal_site: false,
      site_profissional_mensal: false,
      site_profissional_presente: false,
      cartao_interativo_presente: false,
      filtro_instagram_presente: false,
      super_combo: true,
      super_combo_convenio: true,
      ads_presente: false,
      ads_convenio_presente: false,
      show_modal_super_combo: false,
      show_modal_super_combo_convenio: false,
      show_modal_presente_site_profissional: false,
      show_modal_presente_cartao_interativo: false,
      show_modal_presente_filtro_instagram: false,
      show_modal_presente_ads: false,
      show_modal_presente_ads_convenio: false,
      show_offer_ads: false,
      has_show_super_combo_offer: false,
      periodo_pagamento:'anual', 
      periodo_pagamento_arr:[
        { key: 'periodoPagamentoAnual', value: 'anual', text: 'Anual' },
        /*
          { key: 'periodoPagamentoMensal', value: 'mensal', text: 'Mensal' },
        */
      ],
      cep_permite_convenio: false,
      convenio_cep_matriz:[{'cidade': 'Montes Claros', 'inicio': '39400-001', 'fim': ' 39429-999'}],
      cidade_convenio: '',
    };
  }

  verificaCepConvenio = (cep:string, convenio_cep_matriz: any) =>{
    let cepInt = parseInt(cep.replace('-', ''),10);
    // eslint-disable-next-line
    for(let i=0; i<convenio_cep_matriz.length; i++)
    {
      let currentCepInicioInt = parseInt(convenio_cep_matriz[i].inicio.replace('-', ''),10);
      let currentCepFimInt = parseInt(convenio_cep_matriz[i].fim.replace('-', ''),10);
      if(cepInt>=currentCepInicioInt && cepInt<=currentCepFimInt)
      {
        this.setState({cidade_convenio: convenio_cep_matriz[i].cidade, 
          periodo_pagamento: 'convênio oab',
          periodo_pagamento_arr:[
            { key: 'periodoPagamentoConvenioOabAnual', value: 'convênio oab', text: 'Convênio OAB' },
            { key: 'periodoPagamentoAnual', value: 'anual', text: 'Anual' },
            /*
              { key: 'periodoPagamentoMensal', value: 'mensal', text: 'Mensal' },
            */
          ]});
        return;
      }
    }
    this.setState({periodo_pagamento_arr:[
      { key: 'periodoPagamentoAnual', value: 'anual', text: 'Anual' },
      /*
        { key: 'periodoPagamentoMensal', value: 'mensal', text: 'Mensal' },
      */
    ]});
  }

  verificaCepConvenioUF = (uf:string, categoria: string) =>{
    /*
      let ignoreOAB:any = localStorage.getItem('ignoreOAB');
    */

    if((uf.toUpperCase()==='MG' || uf.toUpperCase()==='ES' || uf.toUpperCase()==='AC') && categoria==='advogado')
    {
      this.setState({
        periodo_pagamento: 'convênio oab',
        periodo_pagamento_arr:[
          { key: 'periodoPagamentoConvenioOabAnual', value: 'convênio oab', text: 'Convênio OAB' },
          { key: 'periodoPagamentoAnual', value: 'anual', text: 'Anual' },
          /*
            { key: 'periodoPagamentoMensal', value: 'mensal', text: 'Mensal' },
          */
        ]});
    }
    else{
      this.setState({periodo_pagamento_arr:[
        { key: 'periodoPagamentoAnual', value: 'anual', text: 'Anual' },
        /*
          { key: 'periodoPagamentoMensal', value: 'mensal', text: 'Mensal' },
        */
      ]});
    }
  }

  componentDidMount = async () => {
    let isLogged:any = localStorage.getItem('isLogged');

    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({redirectTo:Rotas.DASHBOARD});
      }
    }

    let registrarEnderecoInfo = null;
    let registrarEnderecoInfoJSONParse = null;
    try {
      registrarEnderecoInfo = localStorage.getItem('registrarEnderecoInfo') as string;
      registrarEnderecoInfoJSONParse = JSON.parse(registrarEnderecoInfo);
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_ENDERECO});
    }
    if(registrarEnderecoInfoJSONParse===null || registrarEnderecoInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_ENDERECO});    
      return;  
    }

    let registrarCategoriaInfo = null;
    let registrarCategoriaInfoJSONParse = null;
    try {
      registrarCategoriaInfo = localStorage.getItem('registrarCategoriaInfo') as string;
      registrarCategoriaInfoJSONParse = JSON.parse(registrarCategoriaInfo);
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_CATEGORIA});
    }
    if(registrarCategoriaInfoJSONParse===null || registrarCategoriaInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_CATEGORIA});    
      return;  
    }

    this.verificaCepConvenioUF(registrarEnderecoInfoJSONParse.estado, registrarCategoriaInfoJSONParse.categoria);
    this.setState({loading:false});
  }

  openOffer = async (offer_name:any) =>{
    this.setState({
      show_modal: true,
      show_offer: true,
      current_promo: offer_name,
    });
  }

  selecionarPlano = async (plano_id:number, plano_name:string) => {
    const {site_profissional_mensal} = this.state;
    let registerInfo = {
      id_plano: plano_id, 
      plano: plano_name, 
      add_site_profissional: site_profissional_mensal,
      site_profissional_presente: false,
      cartao_interativo_presente: false,
      filtro_instagram_presente: false,
      ads_presente: false,
      super_combo: false,
      super_combo_convenio: false,
      cupom: '',
      tipo_plano: this.state.periodo_pagamento,
    };

    this.setState({
      show_modal: false,
    });

    if(this.state.super_combo)
    {
      registerInfo.super_combo = true;
    }
    if(this.state.super_combo_convenio)
    {
      registerInfo.super_combo_convenio = true;
    }
    if(this.state.ads_presente)
    {
      registerInfo.ads_presente = true;
    }

    if(this.state.periodo_pagamento==='convênio oab')
    {
      let countPresentes = 0;
      if(this.state.filtro_instagram_presente)
      {
        countPresentes += 1;
      }
      if(this.state.cartao_interativo_presente)
      {
        countPresentes += 1;
      }
      if(this.state.site_profissional_presente)
      {
        countPresentes += 1;
      }
      if(plano_name==='free_convenio' && countPresentes<1)
      {  
        alert('Atenção! No plano Grátis você deve selecionar 1 presente');
        return;
      }
      // eslint-disable-next-line
      else if(plano_name==='free_convenio' && countPresentes>1)
      {  
        alert('Atenção! No plano Basic você deve selecionar 1 presente');
        return;
      }
      // eslint-disable-next-line
      else if(plano_name==='pro_convenio' && (countPresentes<2 || countPresentes>2))
      {
        alert('Atenção! No plano PRO você deve selecionar 2 presentes');
        return;
      }
      // eslint-disable-next-line
      else if(plano_name==='premium_convenio' && countPresentes<3)
      {
        alert('Atenção! No plano Premium você deve selecionar 3 presentes');
        return;
      }
      if(this.state.filtro_instagram_presente)
      {
        registerInfo.filtro_instagram_presente = true;
      }
      if(this.state.cartao_interativo_presente)
      {
        registerInfo.cartao_interativo_presente = true;
      }
      if(this.state.site_profissional_presente)
      {
        registerInfo.site_profissional_presente = true;
      }

    }
    else if(this.state.periodo_pagamento==='anual')
    {
      let countPresentes = 0;
      if(this.state.filtro_instagram_presente)
      {
        countPresentes += 1;
      }
      if(this.state.cartao_interativo_presente)
      {
        countPresentes += 1;
      }
      if(this.state.site_profissional_presente)
      {
        countPresentes += 1;
      }
      /*
      if(plano_name==='basic_anual' && countPresentes<1)
      {  
        alert('Atenção! No plano Basic você deve selecionar 1 presente');
        return;
      }
      // eslint-disable-next-line
      else if(plano_name==='pro_anual' && (countPresentes<2 || countPresentes>2))
      {
        alert('Atenção! No plano PRO você deve selecionar 2 presentes');
        return;
      }
      // eslint-disable-next-line
      else if(plano_name==='premium_anual' && countPresentes<3)
      {
        alert('Atenção! No plano Premium você deve selecionar 3 presentes');
        return;
      }
      */
      if(this.state.filtro_instagram_presente)
      {
        registerInfo.filtro_instagram_presente = true;
      }
      if(this.state.ads_presente)
      {
        registerInfo.ads_presente = true;
      }
      if(this.state.cartao_interativo_presente)
      {
        registerInfo.cartao_interativo_presente = true;
      }
      if(this.state.site_profissional_presente)
      {
        registerInfo.site_profissional_presente = true;
      }
    }

    /* Mostra ofertas de upgrade Convênio */
    if(plano_name==='free_convenio')
    {
      /* this.openOffer('pro_convenio');      */
      if(!this.state.super_combo_convenio && !this.state.has_show_super_combo_offer)
      {
        this.setState({show_modal_super_combo_convenio: true, has_show_super_combo_offer: true});
      }
      else
      {
        this.selecionarPlano(109,'free_convenio_promo_cancel');
      }
    }
    else if(plano_name==='pro_convenio')
    {
      this.openOffer('premium_convenio');      
    }
    /* Mostra ofertas de upgrade Anual */
    else if(plano_name==='basic_anual')
    {
      this.openOffer('pro_anual');      
    }
    else if(plano_name==='pro_anual')
    {
      this.openOffer('premium_anual');      
    }
    /* Mostra ofertas de upgrade Mensal */
    else if(plano_name==='basic')
    {
      this.openOffer('basic_anual');
    }
    else if(plano_name==='pro')
    {
      this.openOffer('pro_anual');      
    }
    else if(plano_name==='premium')
    {
      this.openOffer('premium_anual');      
    }
    /* Avança o cadastro */
    else
    {
      if(plano_name==='free_convenio_promo_cancel' || plano_name==='basic_convenio_promo_cancel')
      {
        registerInfo.cupom = 'CONVENIOOABMOC';
      }
      else if(plano_name.includes('convenio'))
      {
        registerInfo.cupom = 'CONVENIOOABMOC2';        
      }
      localStorage.setItem('registrarPlanoInfo', JSON.stringify(registerInfo));
      this.setState({redirectTo:Rotas.REGISTRAR_CARTAO});    
    }  
  }

  onChangePeriodoPagamento = (event:any) =>{
    this.setState({periodo_pagamento:event.target.innerText.toLowerCase()});
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='login registrar-plano login-top-desc' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    <span>Experimente grátis!</span>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <CountdownTimer />
                </Grid.Column>
                <Select 
                  placeholder='Selecione o período do pagamento' 
                  options={this.state.periodo_pagamento_arr} 
                  value={this.state.periodo_pagamento}
                  onChange={this.onChangePeriodoPagamento}
                  className='SelectPeriodoPagamento'
                />
              </Grid>
              {
                this.state.periodo_pagamento==='convênio oab' ?
                  <Header className='titulopresente' as='h1'> Aproveite nossos serviços adicionais por 30 dias: </Header>
                :
                  null
              }
              {
                this.state.periodo_pagamento==='convênio oab' ?
                  <div className="containerGroupCheckbox">
                    <Grid columns={1} className='gridCheckboxMultiple checkbox-no-center'>
                      <Grid.Column>
                        <Checkbox 
                          label='Eu quero o Super Combo! Adweb Ads - Tráfego Pago, Email Profissional e Suporte Premium ' 
                          id='super_combo_convenio'
                          checked={this.state.super_combo_convenio}
                          onChange={(e:any,data:any) => {
                            this.setState({super_combo_convenio:false})
                          }}
                          onClick={() => this.setState({show_modal_super_combo_convenio:true})}
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                :
                  null
              }

              {
                this.state.periodo_pagamento==='convênio oab' ?
                  <Header className='titulopresente' as='h1'> Escolha o seu bônus: </Header>
                :
                  <Header className='titulopresente' as='h1'> 30 Dias gratuitos de Super Combo Adweb: </Header>
              }
              {/* Bonus Mensal */}
              {
                this.state.periodo_pagamento==='mensal' ?
                  <Grid columns={1} className='gridSiteProfissionalCheckbox'>
                    <Grid.Column>
                      <Checkbox 
                        label='Site Profissional' 
                        id='site_profissional_mensal'
                        defaultChecked={this.state.site_profissional_mensal}
                        checked={this.state.site_profissional_mensal}
                        onChange={(e:any,data:any) => {
                          this.setState({site_profissional_mensal:false})
                        }}
                        onClick={() => this.setState({show_modal_site:true})}
                      />
                    </Grid.Column>
                  </Grid>
                :
                  null
              }

              {/* Bonus Anual */}
              {
                this.state.periodo_pagamento==='anual' || this.state.periodo_pagamento==='convênio oab' ?
                  <div className='containerGroupCheckbox'>

                    {
                      this.state.periodo_pagamento==='convênio oab' ?
                        <Grid columns={1} className='gridCheckboxMultiple'>
                          <Grid.Column>
                            <Checkbox 
                              label='Site Profissional' 
                              id='site_profissional_presente'
                              defaultChecked={this.state.site_profissional_presente}
                              checked={this.state.site_profissional_presente}
                              onChange={(e:any,data:any) => {
                                this.setState({site_profissional_presente:false})
                              }}
                              onClick={() => this.setState({show_modal_presente_site_profissional:true})}
                            />
                          </Grid.Column>
                        </Grid>
                      :
                        null
                    }

                    {
                      this.state.periodo_pagamento==='convênio oab' ?
                        <Grid columns={1} className='gridCheckboxMultiple'>
                          <Grid.Column>
                            <Checkbox 
                              label='Cartão Interativo' 
                              id='cartao_interativo_presente'
                              defaultChecked={this.state.cartao_interativo_presente}
                              checked={this.state.cartao_interativo_presente}
                              onChange={(e:any,data:any) => {
                                this.setState({cartao_interativo_presente:false})
                              }}
                              onClick={() => this.setState({show_modal_presente_cartao_interativo:true})}
                            />
                          </Grid.Column>
                        </Grid>
                      :
                        null
                    }

                    {
                      this.state.periodo_pagamento==='convênio oab' ?
                        <Grid columns={1} className='gridCheckboxMultiple'>
                          <Grid.Column>
                            <Checkbox 
                              label='Filtro Instagram' 
                              id='filtro_instagram_presente'
                              defaultChecked={this.state.filtro_instagram_presente}
                              checked={this.state.filtro_instagram_presente}
                              onChange={(e:any,data:any) => {
                                this.setState({filtro_instagram_presente:false})
                              }}
                              onClick={() => this.setState({show_modal_presente_filtro_instagram:true})}
                            />
                          </Grid.Column>
                        </Grid>
                      :
                        null
                    }

                    {
                      this.state.periodo_pagamento!=='convênio oab' ?
                        <Grid columns={1} className='gridCheckboxMultiple checkbox-no-center'>
                          <Grid.Column>
                            <Checkbox 
                              label='Eu quero o Super Combo! Site Profissional, Adweb Ads - Tráfego Pago e Email Profissional' 
                              id='super_combo'
                              checked={this.state.super_combo}
                              onChange={(e:any,data:any) => {
                                this.setState({super_combo:false})
                              }}
                              onClick={() => this.setState({show_modal_super_combo:true})}
                            />
                          </Grid.Column>
                        </Grid>
                      :
                        null
                    }
                  </div>
                :
                  null
              }
              <Grid columns={1}>
                {/* Cards Convênio OAB */}
                {
                  this.state.periodo_pagamento==='convênio oab' ?
                    <Grid.Column>
                      <Grid columns={3}>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoGratisConvenioImg} centered onClick={() => this.selecionarPlano(109,'free_convenio')}/>
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoProConvenioImg} centered onClick={() => this.selecionarPlano(76,'pro_convenio')} />
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoPremiumConvenioImg} centered onClick={() => this.selecionarPlano(77,'premium_convenio')} />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  :
                    null
                }
                {/* Cards Anual */}
                {
                  this.state.periodo_pagamento==='anual'?
                    <Grid.Column>
                      <Grid columns={3}>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoBasicAnualImg} centered onClick={() => this.selecionarPlano(75,'basic_anual')}/>
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoProAnualImg} centered onClick={() => this.selecionarPlano(76,'pro_anual')} />
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoPremiumAnualImg} centered onClick={() => this.selecionarPlano(77,'premium_anual')} />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  :
                    null
                }
                {/* Cards Mensal */}
                {
                  this.state.periodo_pagamento==='mensal' ?
                    <Grid.Column>
                      <Grid columns={3}>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoBasicImg} centered onClick={() => this.selecionarPlano(64,'basic')}/>
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoProImg} centered onClick={() => this.selecionarPlano(65,'pro')} />
                        </Grid.Column>
                        <Grid.Column>
                          <Image className='ImgPlano' src={PlanoPremiumImg} centered onClick={() => this.selecionarPlano(66,'premium')} />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  :
                    null
                }
              </Grid>
            </Segment>
          </Grid.Column>
          {/* Modal Site Profissional - Mensal */}
          <Modal
            onClose={() => this.setState({show_modal_site:false})}
            onOpen={() => this.setState({show_modal_site:true})}
            open={this.state.show_modal_site} >
            <Modal.Header>Site Profissional</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModal'>
                  <span>Crie seu site em poucos minutos!</span>
                </Header>
                <Header as='h3' className='subTitleModal'>
                  <span>R$ 19,99/Mês</span>
                </Header>
                <ul className='listModal'>
                  <li>Assessoria na configuração e aquisição do domínio próprio</li>
                  <li>Hospedagem</li>
                  <li>Otimizado com Google e Facebook</li>
                  <li>Notícias Automáticas</li>
                  <li>Integrado com WhatsApp</li>
                  <li>Construtor de site ultra fácil</li>
                  <li>Temas premium</li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.setState({show_modal_site:false})}>
                Agora não
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_site:false, site_profissional_mensal: true})}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Filtro Instagram */}
          <Modal
            onClose={() => this.setState({show_modal_presente_filtro_instagram:false})}
            onOpen={() => this.setState({show_modal_presente_filtro_instagram:true})}
            open={this.state.show_modal_presente_filtro_instagram} >
            <Modal.Header>Filtro do Instagram!</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModalFiltroInstagram'>
                  <span>Filtro do Instagram personalizado com sua marca</span>
                </Header>
                <ul className='listModal'>
                  <li>Disponível em até 48 horas úteis após a efetivação do plano</li>
                  <li>Temas Premium</li>
                  <li>Aumento de 84% do engajamento nas suas publicações de acordo o Facebook/Instagram</li>
                  <li>Veja alguns dos nossos modelos <a href='https://materiais.advogar.site/filtros-modelos' target='_blank' rel='noreferrer'>clicando aqui.</a></li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.setState({show_modal_presente_filtro_instagram:false})}>
                Fechar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_presente_filtro_instagram:false, filtro_instagram_presente: true})}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Ads */}
          <Modal
            onClose={() => this.setState({show_modal_presente_ads:false})}
            onOpen={() => this.setState({show_modal_presente_ads:true})}
            open={this.state.show_modal_presente_ads} >
            <Modal.Header>Adweb Ads - Tráfego pago R$159,99 mês após 30 dias</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h3' className='titleModalAds'>
                  <span>Ganhe 1200 reais de crédito no Google Ads para usar com Tráfego pago!</span>
                </Header>
                <Header as='h3' className='titleModalAds'>
                  <span>Valor promocional: de R$199,99/mês por 159,99/mês - primeiro pagamento após 30 dias</span>
                </Header>
                <ul className='listModal'>
                  <li>Gerenciamento automático;</li>
                  <li>Públicos inteligentes;</li>
                  <li>Orçamento flexível;</li>
                  <li>R$1200,00 de bônus no Google Ads para novos anunciantes (<a href='https://adwebapp.com/ads-anuncio-online/#support' target='_blank' rel='noreferrer'>clique aqui para saber mais</a>)</li>
                  <li>Seu WhatsApp vai bombar de clientes.</li>
                  <li>* Consultar condições!</li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => {
                  this.setState({show_modal_presente_ads:false});
                  this.selecionarPlano(109,'free_convenio_promo_cancel');
                }}>
                Fechar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => {
                 this.setState({show_modal_presente_ads:false, ads_presente: true});
                  this.selecionarPlano(109,'free_convenio_promo_cancel');
                }}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Ads Convênio */}
          <Modal
            onClose={() => this.setState({show_modal_presente_ads_convenio:false})}
            onOpen={() => this.setState({show_modal_presente_ads_convenio:true})}
            open={this.state.show_modal_presente_ads_convenio} >
            <Modal.Header>Adweb Ads - Tráfego pago R$139,99 mês após 30 dias</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h3' className='titleModalAds'>
                  <span>Ganhe 1200 reais de crédito no Google Ads para usar com Tráfego pago!</span>
                </Header>
                <Header as='h3' className='titleModalAds'>
                  <span>Valor promocional: de R$199,99/mês por 139,99/mês - primeiro pagamento após 30 dias</span>
                </Header>
                <ul className='listModal'>
                  <li>Gerenciamento automático;</li>
                  <li>Públicos inteligentes;</li>
                  <li>Orçamento flexível;</li>
                  <li>R$1200,00 de bônus no Google Ads para novos anunciantes (<a href='https://adwebapp.com/ads-anuncio-online/#support' target='_blank' rel='noreferrer'>clique aqui para saber mais</a>)</li>
                  <li>Seu WhatsApp vai bombar de clientes.</li>
                  <li>* Consultar condições!</li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => {
                  this.setState({show_modal_presente_ads_convenio:false});
                  this.selecionarPlano(109,'free_convenio_promo_cancel');
                }}>
                Fechar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => {
                 this.setState({show_modal_presente_ads_convenio:false, ads_convenio_presente: true});
                  this.selecionarPlano(109,'free_convenio_promo_cancel');
                }}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Site Profissional */}
          <Modal
            onClose={() => this.setState({show_modal_presente_site_profissional:false})}
            onOpen={() => this.setState({show_modal_presente_site_profissional:true})}
            open={this.state.show_modal_presente_site_profissional} >
            {
              this.state.periodo_pagamento==='convênio oab' ?
                <Modal.Header>Site Profissional</Modal.Header>
              :
                <Modal.Header>Site Profissional - R$19,99/mês após 30 dias</Modal.Header>
            }
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModalSiteProfissional'>
                  <span>O site profissional potencializa sua marca, gerando mais audiência e consequentemente mais clientes.</span>
                </Header>
                <h3>Personalizado com sua marca:</h3>
                <ul className='listModal'>
                  <li>Hospedagem</li>
                  <li>Construtor de site ultra-fácil</li>
                  <li>Assessoria na compra e configuração do domínio próprio</li>
                  <li>Integração com WhatsApp do WhatsApp</li>
                  <li>Integração com Pixel do Facebook e Google</li>
                  <li>SEO</li>
                  <li>Temas Premium</li>
                  <li>Adequação a LGPD</li>
                  <li>Conheça alguns dos nossos modelos <a href='https://materiais.advogar.site/site-profissional-modelos' target='_blank' rel='noreferrer'>clicando aqui.</a></li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.setState({show_modal_presente_site_profissional:false})}>
                Fechar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_presente_site_profissional:false, site_profissional_presente: true})}
                positive
              />
            </Modal.Actions>
          </Modal>


          {/* Modal Bonus Super Combo */}
          <Modal
            onClose={() => this.setState({show_modal_super_combo:false})}
            onOpen={() => this.setState({show_modal_super_combo:true})}
            open={this.state.show_modal_super_combo} >
            <Modal.Header>Super Combo Adweb</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModalCartaoInterativo'>
                  <span>30 dias gratuitos!</span>
                </Header>
                <ul className='listModal'>
                  <li>Adweb Ads - Tráfego pago</li>
                  <li>Site Profissional</li>
                  <li>05 contas de E-mail Profissional e 10 Gigas </li>
                  <li>Suporte Premium</li>
                </ul>
                <Header as='h4' className='titleModalCartaoInterativo'>
                  <span>R$159,99/mês após 30 dias </span>
                </Header>
                <Header as='h6' className='titleModalCartaoInterativo'>
                  <span>Cancele a qualquer momento</span>
                </Header>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' onClick={() => this.setState({show_modal_super_combo:false, super_combo: false})}>
                Recusar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_super_combo:false, super_combo: true})}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Super Combo */}
          <Modal
            onClose={() => this.setState({show_modal_super_combo_convenio:false})}
            onOpen={() => this.setState({show_modal_super_combo_convenio:true})}
            open={this.state.show_modal_super_combo_convenio} >
            <Modal.Header>Super combo Adweb</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModalCartaoInterativo'>
                  <span>30 dias gratuitos!</span>
                </Header>
                <ul className='listModal'>
                  <li>Adweb Ads - Tráfego Pago no Google Ads;</li>
                  <li>05 Contas de Email Profissional;</li>
                  <li>Suporte Premium</li>
                </ul>
                <Header as='h4' className='titleModalCartaoInterativo'>
                  <span>De R$159,99 por R$139,99/mês com primeiro pagamento após 30 dias.</span>
                </Header>
                <Header as='h6' className='titleModalCartaoInterativo'>
                  <span>Cancele a qualquer momento</span>
                </Header>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' onClick={() => this.setState({show_modal_super_combo_convenio:false, super_combo_convenio: false})}>
                Recusar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_super_combo_convenio:false, super_combo_convenio: true})}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Bonus Cartão Interativo */}
          <Modal
            onClose={() => this.setState({show_modal_presente_cartao_interativo:false})}
            onOpen={() => this.setState({show_modal_presente_cartao_interativo:true})}
            open={this.state.show_modal_presente_cartao_interativo} >
            {
              this.state.periodo_pagamento==='convênio oab' ?
                <Modal.Header>Cartão Interativo Digital</Modal.Header>
              :
                <Modal.Header>Cartão Interativo Digital - R$19,99/mês após 30 dias</Modal.Header>
            }
            <Modal.Content image>
              <Modal.Description>
                <Header as='h1' className='titleModalCartaoInterativo'>
                  <span>O cartão interativo digital gera autoria e credibilidade no relacionamento com o cliente. Por ser prático o compartilhamento, se torna também um canal de divulgação da sua marca ou serviço.</span>
                </Header>
                <ul className='listModal'>
                  <li>Pronto em até 10 minutos</li>
                  <li>Personalizado com sua marca</li>
                  <li>Temas premium</li>
                  <li>Conheça alguns exemplos <a href='https://materiais.advogar.site/cartao-interativo-modelos' target='_blank' rel='noreferrer'>clicando aqui.</a></li>
                </ul>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.setState({show_modal_presente_cartao_interativo:false})}>
                Fechar
              </Button>
              <Button
                content='Adicionar'
                labelPosition='right'
                icon='checkmark'
                onClick={() => this.setState({show_modal_presente_cartao_interativo:false, cartao_interativo_presente: true})}
                positive
              />
            </Modal.Actions>
          </Modal>

          {/* Modal Oferta - upgrade */}
          <Modal
            onClose={() => this.setState({show_modal:false})}
            onOpen={() => this.setState({show_modal:true})}
            open={this.state.show_modal} >
            <Modal.Header>Super oferta pra hoje!</Modal.Header>

            {/* Modal Content Convenio */}
            {
              this.state.periodo_pagamento==='convênio oab' ?
                <Modal.Content image>
                  {/* pro_convenio */}
                  {
                    this.state.current_promo==='pro_convenio' ?
                      <Modal.Description>
                        <Header as='h1' className='titleModal'>
                          <span>PROMOÇÃO</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>Experimente nossa ferramenta Gratuitamente</span>
                        </Header>
                        <ul className='listModal'>
                          <li>300/mês Posts de Notícias jurídicas pra alimentar suas redes sociais;</li>
                          <li>20 Mídias de Conteúdo jurídico;</li>
                          <li>20 Templates  para criar o que quiser;</li>
                          <li>250/mês Posts de Frases de Inspiração;</li>
                          <li>Após 14 dias 22,49/mês cobrado anualmente;</li>
                        </ul>
                      </Modal.Description>
                    : 
                      null
                  }

                  {/* premium_convenio */}
                  {
                    this.state.current_promo==='premium_convenio' ?
                      <Modal.Description>
                        <Header as='h1' className='titleModal'>
                          <span>PROMOÇÃO</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>Explore ao máximo nossa ferramenta Gratuitamente</span>
                        </Header>
                        <ul className='listModal'>
                          <li>300/mês Posts de Notícias jurídicas pra alimentar suas redes sociais;</li>
                          <li>20 Mídias de Conteúdo jurídico;</li>
                          <li>20 Templates  para criar o que quiser;</li>
                          <li>250/mês Posts de Frases de Inspiração;</li>
                          <li>300/ano Posts de Datas Comemorativas;</li>
                          <li>Após 14 dias 29,99/mês cobrado anualmente;</li>
                        </ul>
                      </Modal.Description>
                    : 
                      null
                  }
                </Modal.Content>
              :
                null
            }

            {/* Modal Content Mensal */}
            {
              this.state.periodo_pagamento==='mensal' ?
                <Modal.Content image>
                  {/* basic_anual */}
                  {
                    this.state.current_promo==='basic_anual' ?
                      <Modal.Description>
                        <Header as='h1' className='titleModal'>
                          <span>BASIC ANUAL COBRADO MENSALMENTE</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 19,99/Mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos os serviços do plano Basic mensal +</li>
                          <li>SITE PROFISSIONAL GRÁTIS</li>
                          <li>Economia de 40%</li>
                          <li>De R$29,99 por R$19,99/mês</li>
                        </ul>
                      </Modal.Description>
                    : 
                      null
                  }
                  {/* pro_anual */}
                  {
                    this.state.current_promo==='pro_anual' ?
                      <Modal.Description>
                        <Header as='h1' className='titleModal'>
                          <span>PRO ANUAL COBRADO MENSALMENTE</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 29,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos os serviços do plano PRO Mensal +</li>
                          <li>SITE PROFISSIONAL GRÁTIS</li>
                          <li>Economia de 40%</li>
                          <li>De R$39,99 por R$29,99/mês</li>
                        </ul>
                      </Modal.Description>
                    : 
                      null
                  }
                  {/* premium_anual */}
                  {
                    this.state.current_promo==='premium_anual' ?
                      <Modal.Description>
                        <Header as='h1' className='titleModal'>
                          <span>PREMIUM ANUAL COBRADO MENSALMENTE</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 39,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos os serviços do plano PRO Mensal +</li>
                          <li>SITE PROFISSIONAL GRÁTIS</li>
                          <li>Economia de 40%</li>
                          <li>De R$69,99 por R$39,99/mês</li>
                        </ul>
                      </Modal.Description>
                    : 
                      null
                  }
                </Modal.Content>
              :
                null
            }

            {/* Modal Content Anual */}
            {
              this.state.periodo_pagamento==='anual' ?
                this.state.current_promo.includes('pro') ?
                  <Modal.Content image>
                    <Modal.Description>
                      <Header as='h1' className='titleModal'>
                        <span>Plano Pro</span>
                      </Header>
                      <Header as='h3' className='subTitleModal'>
                        <span>R$ 9,99</span>
                      </Header>
                      <ul className='listModal'>
                        <li>Deseja adicionar 200 frases inspiraçao + Site profissional em formato de Story no seu plano por apenas 9,99/mês?</li>
                      </ul>
                    </Modal.Description>
                  </Modal.Content>
                : 
                  <Modal.Content image>
                    <Modal.Description>
                      <Header as='h1' className='titleModal'>
                        <span>Plano Premium</span>
                      </Header>
                      <Header as='h3' className='subTitleModal'>
                        <span>R$ 9,99</span>
                      </Header>
                      <ul className='listModal'>
                        <li>Deseja adicionar Datas Comemorativas  (+300 datas por ano)  no seu plano por apenas 9,99/mês?</li>
                      </ul>
                    </Modal.Description>
                  </Modal.Content>
              :
                null
            }

            {/* Actions Convênio */}
            {
              this.state.periodo_pagamento==='convênio oab' ?
                <Modal.Actions>
                  {/* pro_convenio */}
                  {
                    this.state.current_promo==='pro_convenio' ?
                      <div>
                        <Button color='black' onClick={() => this.selecionarPlano(109,'free_convenio_promo_cancel')}>
                          Agora não
                        </Button>
                        <Button
                          content='Experimentar'
                          labelPosition='right'
                          icon='checkmark'
                          onClick={() => this.selecionarPlano(76,'pro_convenio_promo')}
                          positive
                        />
                      </div>    
                    :
                      null
                  }

                  {/* premium_convenio */}
                  {
                    this.state.current_promo==='premium_convenio' ?
                      <div>
                        <Button color='black' onClick={() => this.selecionarPlano(76,'pro_convenio_promo_cancel')}>
                          Agora não
                        </Button>
                        <Button
                          content='Experimentar'
                          labelPosition='right'
                          icon='checkmark'
                          onClick={() => this.selecionarPlano(77,'premium_convenio_promo')}
                          positive
                        />
                      </div>    
                    :
                      null
                  }

                </Modal.Actions>
              :
                null
            }

            {/* Actions Mensal */}
            {
              this.state.periodo_pagamento==='mensal' ?
                <Modal.Actions>
                  {/* basic_anual */}
                  {
                    this.state.current_promo==='basic_anual' ?
                      <div>
                        <Button color='black' onClick={() => this.selecionarPlano(64,'basic_promo_cancel')}>
                          Agora não
                        </Button>
                        <Button
                          content='Experimentar'
                          labelPosition='right'
                          icon='checkmark'
                          onClick={() => this.selecionarPlano(65,'basic_promo')}
                          positive
                        />
                      </div>    
                    :
                      null
                  }
                  {/* pro_anual */}
                  {
                    this.state.current_promo==='pro_anual' ?
                      <div>
                        <Button color='black' onClick={() => this.selecionarPlano(64,'pro_promo_cancel')}>
                          Agora não
                        </Button>
                        <Button
                          content='Experimentar'
                          labelPosition='right'
                          icon='checkmark'
                          onClick={() => this.selecionarPlano(65,'pro_promo')}
                          positive
                        />
                      </div>                     
                    :
                      null
                  }
                  {/* premium_anual */}
                  {
                    this.state.current_promo==='premium_anual' ?
                      <div>
                        <Button color='black' onClick={() => this.selecionarPlano(65,'premium_promo_cancel')}>
                          Agora não
                        </Button>
                        <Button
                          content='Experimentar'
                          labelPosition='right'
                          icon='checkmark'
                          onClick={() => this.selecionarPlano(66,'pro_promo')}
                          positive
                        />
                      </div>                     
                    :
                      null
                  }
                </Modal.Actions>
              :
                null
            }

            {/* Actions Anual */}
            {
              this.state.periodo_pagamento==='anual' ?
                this.state.current_promo==='pro_anual' ?
                  <Modal.Actions>
                    <Button color='black' onClick={() => this.selecionarPlano(75,'basic_anual_cancel')}>
                      Agora não
                    </Button>
                    <Button
                      content='Experimentar'
                      labelPosition='right'
                      icon='checkmark'
                      onClick={() => this.selecionarPlano(76,'pro_anual_cancel')}
                      positive
                    />
                  </Modal.Actions>
                :
                  <Modal.Actions>
                    <Button color='black' onClick={() => this.selecionarPlano(76,'pro_anual_cancel')}>
                      Agora não
                    </Button>
                    <Button
                      content='Experimentar'
                      labelPosition='right'
                      icon='checkmark'
                      onClick={() => this.selecionarPlano(77,'premium_promo_anual')}
                      positive
                    />
                  </Modal.Actions>
              :
                null
            }
          </Modal>
        </Grid>
        
      </>
    );
  }
};

