import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image,
  Card 
} from 'semantic-ui-react';
import md5 from 'js-md5';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import IModelo from '../../models/modelo';
import IArea from '../../models/area';
import IUsuario from '../../models/usuario';
import IUsuarioMedusa from '../../models/usuarioMedusa';
import './index.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';
import EditorModeloService from '../../services/editorModeloService';
import UsuarioMedusaService from '../../services/usuarioMedusaService';
import UsuarioService from '../../services/usuarioService';
import Loading from '../../components/Loading';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  id:string, 
  id_hash: string,
  redirectTo:any, 
  loading:boolean, 
  modalFiltroOpen:boolean, 
  searchString: string, 
  hasAccess:boolean, 
  modelo:IModelo | null, 
  areaPai:IArea | null, 
  usuarioMedusa:IUsuarioMedusa | null, 
  usuarioInfo:IUsuario | any,
  whatsappUrl:string, 
  token:string | null, 
};
export default class Modelo extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      id: this.props.match.params.id, 
      id_hash: '',
      redirectTo: false, 
      loading:true, 
      modalFiltroOpen:false, 
      searchString: '', 
      hasAccess:false, 
      modelo: null,
      areaPai: null,
      usuarioMedusa: null,
      usuarioInfo: null,
      whatsappUrl: 'https://api.whatsapp.com/send?phone=5538991999244&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20adicionais%20',
      token: ''
    };
  }

  componentDidMount = async () => {
    const {id} = this.state;
    await this.loadModelo(parseInt(id,10));
    this.handleIframeTask = this.handleIframeTask.bind(this);
    document.addEventListener('message', this.handleIframeTask);
    window.addEventListener('message', this.handleIframeTask);
    window.onmessage = (event:any) => {
      this.handleIframeMessages(event);
    };
    this.setState({loading:false});
  }

  componentDidUpdate = async () =>{
    if(this.state.hasAccess)
    {
      window.scrollTo(0, 150);
    }
  }

  handleIframeMessages = async (e:any) =>{
    console.log('onmessage',e.data);
    if (e.data !== undefined && e.data !== null) 
    {
      const dataJson = JSON.parse(e.data);
      const {modelo} = this.state;
      console.log('modelo', modelo);
      if(dataJson.type==='export' && modelo!==null)
      {
        let modeloClienteID = modelo.id ? modelo.id : 0;
        if(modelo.cliente===null)
        {
          /*
          const body = {
            'area_atuacao':String(modelo.area_atuacao),
            'titulo':String(modelo.titulo),
            'tipo':String(modelo.tipo),
            'json':JSON.stringify(dataJson.content),
          };
          console.log('exporting new model', body);
          */

          const bodyNew = new FormData();
          bodyNew.append('area_atuacao', String(modelo.area_atuacao));
          bodyNew.append('titulo', dataJson.title);
          bodyNew.append('tipo', String(modelo.tipo));
          bodyNew.append('json', dataJson.content);
          console.log('bodyNew',bodyNew);
          const modeloResponse = await EditorModeloService.postSalvarModelo(bodyNew);
          modeloClienteID = modeloResponse.id;
        }
        console.log('modeloClienteID', modeloClienteID);
        const updateBody = new FormData();
        updateBody.append('area_atuacao', String(modelo.area_atuacao));
        updateBody.append('titulo', dataJson.title);
        updateBody.append('tipo', String(modelo.tipo));
        updateBody.append('preview', dataJson.preview);
        updateBody.append('json', dataJson.content);
        console.log('updateBody',updateBody);

        /*
        const updateBody = {
          'area_atuacao':String(modelo.area_atuacao),
          'titulo':String(modelo.titulo),
          'tipo':String(modelo.tipo),
          'preview':dataJson.preview,
          'json':JSON.stringify(dataJson.content),
        };
        */
        const modeloUpdateResponse = await EditorModeloService.putAtualizarModelo(modeloClienteID, updateBody);
        console.log('modeloUpdateResponse', modeloUpdateResponse);
        alert('Seu modelo foi salvo com sucesso!');
        this.setState({redirectTo:Rotas.MEUS_MODELOS});
      }
    }
  };

  handleIframeTask = (e:any) => {
    if (e.origin !== 'https://localhost:3000/') {
      return;
    }
    console.log('handleIframeTask', e.data);
  };

  loadModelo = async (id_modelo:number) => {
    const modeloResponse = await EditorAreaService.getModelo(id_modelo);
    this.setState({modelo:modeloResponse});

    const localToken = localStorage.getItem('token');
    this.setState({token:localToken});

    const medusaInfo = await UsuarioMedusaService.get(localToken);
    this.setState({usuarioMedusa:medusaInfo});

    const areaInfoResponse = await EditorAreaService.getAreaInfo(modeloResponse.area_atuacao);
    this.setState({areaPai:areaInfoResponse});

    const usuarioAdvInfo = await UsuarioService.usuarioInfo(localToken);
    const hash = md5(String(modeloResponse.id));
    const H = `${String(modeloResponse.id)}-${constants.APP_KEY_EDITOR}-${hash}`;
    this.setState({usuarioInfo:usuarioAdvInfo, id_hash: H});

    if(areaInfoResponse.tipo_venda!=='GRATIS')
    {
      if(medusaInfo.tipos_areas_atuacao.includes(areaInfoResponse.tipo_venda))
      {
        this.setState({hasAccess:true});
      }
      else
      {
        alert('Você não possuí acesso a esse recurso e será redirecionado para nosso suporte!');
        this.openInNewTab(this.state.whatsappUrl);
        this.setState({redirectTo:Rotas.MEUS_MODELOS});
      }
    }
    else
    {
      this.setState({hasAccess:true});
    }
  };

  getAreaInfo = async (id_area:number) => {
    const areaInfoResponse = await EditorAreaService.getAreaInfo(id_area);
    this.setState({areaPai:areaInfoResponse});
  };

  openInNewTab = (url:any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if(newWindow) newWindow.opener = null;
  };

  updateAccess = (condition:boolean) =>{
    this.setState({hasAccess:condition});
  };

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    const modalButton = (
      <Button inverted>
        <Icon name='list alternate' />
        Filtros
      </Button>
    );
    const modeloContent = `<iframe className="iframeFull" id="modeloContent" name="modeloContent" src='${constants.BASE_URL}/editor/ui/?modelo=${this.state.id}&hk=${this.state.id_hash}&client_id=${this.state?.usuarioInfo?.id_cliente}'></iframe>`;

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Container fluid className='midias-areas modelo-page page'>
        <Menu stackable fluid borderless className='menu-advogar' attached='top'>
          <Menu.Menu className='menu-button'>
            <Menu.Item>
              <MenuAdvogar />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item className='menu-breadcrumb'>
            <Breadcrumb>
              {breadCrumb[Rotas.CRIAR_MIDIAS].map(bread =>
                bread.link ? (
                  <>
                    <Breadcrumb.Section
                      as={NavLink}
                      to={bread.key}
                      active={bread.active}
                    >
                      {bread.content}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                  </>
                ) : (
                  <Breadcrumb.Section active={bread.active}>
                    {bread.content}
                  </Breadcrumb.Section>
                )
              )}
            </Breadcrumb>
          </Menu.Item>
          <Menu.Item className='menu-go-to-model'>
            <a href="#modeloContent" className='btnGoToModel'>Ir para modelo</a>
          </Menu.Item>
        </Menu>
        <Container fluid className='container-body'>
          {
            this.state.hasAccess ?
              <div dangerouslySetInnerHTML={ {__html: modeloContent}} />
            :
              <div/>
          }
        </Container>
      </Container>
    );
  }
};

