import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
} from 'semantic-ui-react';

import Rotas from '../../utils/rotas';
import constants from '../../utils/constantes';
import './login.scss';
import Logo from '../../assets/imgs/logo.png';
import AndroidStoreIMG from '../../assets/imgs/android-store.png';
import IosStoreIMG from '../../assets/imgs/ios-store.png';
import UsuarioMedusaService from '../../services/usuarioMedusaService';
import IAuth from '../../models/auth';

import FormField from '../../components/FormField';
import Loading from '../../components/Loading';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  logo_original: any, 
  uploadSuccess:boolean, 
};

export default class UploadLogo extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      logo_original: '',
      uploadSuccess: false, 
    };
  }

  componentDidMount = async () => {
    this.setState({loading:false});
  }

  onFileChange = (event:any) => {
    console.log('onFileChange e', event);
    this.setState({ logo_original: event.target.files[0] });
  }

  redirectDashboard = async () => {
    this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
  }

  onFileUpload = async () => {
    this.setState({loading:true});
    const formData = new FormData();
  
    formData.append('logo_original', this.state.logo_original);
    /*
    formData.append(
      'logo_original',
      this.state.logo_original,
      this.state.logo_original.name
    );
    */

    console.log(this.state.logo_original);
    const token = localStorage.getItem('token');
    let response = UsuarioMedusaService.uploadLogo(formData, token);
    console.log('response', response);
    this.setState({loading:false, uploadSuccess: true});
  
    /*
    // Request made to the backend api
    // Send formData object
    axios.post('api/uploadfile', formData);
    */
    /*
    let response = await fetch(`${constants.BASE_URL_API}usuario-medusa/`,{ 
      method: 'PUT',
      headers:{  
       'Content-Type': 'multipart/form-data',
       'Authorization': `Token ${token}`
      }, 
      body: formData
    });

    let responseJson = await response.json();
    */

  }

  openLink = (link:string) => {
    window.open(link, '_blank');
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='uploadLogo' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid centered columns={1} padded='vertically'>
                <Grid.Column>
                  <Image src={Logo} centered />
                </Grid.Column>
              </Grid>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    Upload <span>da logo</span>
                  </Header>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                {
                  this.state.uploadSuccess ?
                    <Grid.Column className='uploadLogoSuccess'>
                      <Header as='h1' className='uploadLogo_text1'>
                        <span>Logomarca enviada com sucesso! <br/> No prazo de 48h úteis nossa equipe humana  vai fazer uma inspeção minuciosa para que você consiga utilizar o máximo da sua logomarca nas nossas mídias. Qualquer dúvida entre em contato através do  suporte.</span>
                      </Header>
                      {
                        window.innerWidth <= 480 ?
                          <Grid columns={2}>
                            <Grid.Column className='uploadLogoColumnStore'>
                              <Image className='ImgStore' src={AndroidStoreIMG} centered onClick={() => this.openLink('https://play.google.com/store/apps/details?id=com.advogarwebmobile&hl=pt_BR')}/>
                            </Grid.Column>
                            <Grid.Column className='uploadLogoColumnStore'>
                              <Image className='ImgStore' src={IosStoreIMG} centered onClick={() => this.openLink('https://apps.apple.com/br/app/advogar-web/id1483803142')}/>
                            </Grid.Column>
                          </Grid>
                        :
                          <Grid columns={1}>
                            <Grid.Column>
                              <Button
                                primary
                                size='large'
                                fluid
                                onClick={this.redirectDashboard}
                              >
                                Continuar
                              </Button>
                            </Grid.Column>
                          </Grid>
                      }
                    </Grid.Column>
                  :
                    <Grid.Column>
                      {
                        this.state.hasError ? 
                        <div className='ErrorLogin'>{this.state.errorMessage}</div>
                        : 
                        null
                      }
                      <Header as='h1' className='uploadLogo_text1'>
                        <span>Você não possui logo cadastrada, por favor, insira uma logo para continuar!</span>
                      </Header>
                      <Header as='h3' className='uploadLogo_text2'>
                        <span>Favor enviar sua logomarca com alta qualidade com fundo branco ou transparente (de preferência) em formato PNG ou JPEG. Nossa equipe de design vai fazer uma revisão na sua logo em até 48horas úteis.</span>
                      </Header>
                      <div className='ErrorLogin'>Favor, não enviar foto!</div>
                      <Form>
                        <Grid columns={1}>
                          <Grid.Column>
                            <Form.Field className='form-field'>
                              <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                              <label htmlFor='logo'>Selecionar Logo</label>
                              <input
                                type='file'
                                id='logo'
                                placeholder='Selecionar logo para upload...'
                                onChange={this.onFileChange}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                      </Form>
                      <Grid columns={1}>
                        <Grid.Column>
                          <Button
                            primary
                            size='large'
                            fluid
                            onClick={this.onFileUpload}
                          >
                            Enviar
                          </Button>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                }
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
        
        <a className='whatsapp-widget' 
            rel='noopener noreferrer'
            target='_blank'
            href='https://api.whatsapp.com/send?phone=5538991999244&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20adicionais%20' 
            title='Show Chat'>
              <svg width='20' viewBox='0 0 24 24'><defs/><path fill='#eceff1' d='M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z'/><path fill='#4caf50' d='M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z'/><path fill='#fafafa' d='M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z'/></svg> Entrar em contato
        </a>
      </>
    );
  }
};

