const constants = {
  APP_KEY_EDITOR: 'Y48mVYqWYnPUUWkL',
  IMGURL: 'https://res.cloudinary.com/edsondota/',
  BASE_URL: 'https://homologacao.mobile.advogar.site/',
  BASE_URL_API: 'https://homologacao.mobile.advogar.site/api/',
  DEV_BASE_URL: 'http://127.0.0.1:8000/',
  DEV_BASE_URL_API: 'http://127.0.0.1:8000/api/',
};
/* https://mobile.advogar.site/ 
  homologacao.mobile.advogar.site
*/

export default constants;
